<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>账单列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <div class="listbox">
                <div class="listright" v-loading="loading">
                    <div class="search">
                        <div class="searchleft">
                            <el-form :inline="true" :model="searchform" class="demo-form-inline" @submit.native.prevent >
                                <el-form-item label="交易号">
                                    <el-input v-model="searchform.claveRastreo" style="width: 220px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('time')">
                                    <el-date-picker
                                    v-model="value1"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item>
                                    <el-button size="small" type="primary" @click="onSubmit">{{$t('query')}}</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <el-table :data="list"
                        :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}" style="width: 100%">
                        <el-table-column prop="createTime" label="创建时间" :width="160">
                            <template slot-scope="scope">
                                {{scope.row.createTime|changedate}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="orgId" label="商户id" width="100px"></el-table-column>
                        <el-table-column prop="appId" label="账户" width="100px"></el-table-column>
                        <el-table-column prop="accountId" label="应用id"></el-table-column>
                        <el-table-column prop="claveRastreo" label="交易号"></el-table-column>
                        <el-table-column prop="status" label="状态">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.status==1" type="primary" size="mini" :title="$t('shz')">{{$t('shz')}}</el-tag>
                                <el-tag v-if="scope.row.status==2" type="info" size="mini" :title="$t('yfk')">{{$t('yfk')}}</el-tag>
                                <el-tag v-if="scope.row.status==3" type="warning" size="mini" :title="$t('fksb')">{{$t('fksb')}}</el-tag>
                                <el-tag v-if="scope.row.status==4" type="success" size="mini" :title="$t('yhk')">{{$t('yhk')}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="monto" label="付款金额"></el-table-column>
                        <el-table-column prop="cost" label="服务费"></el-table-column>
                        <el-table-column prop="balance" label="扣除余额"></el-table-column>
                        <el-table-column prop="nombreBeneficiario" label="用户姓名"></el-table-column>
                        <el-table-column prop="cuentaBeneficiario" label="用户账户"></el-table-column>
                    </el-table>
                    <div class="page">
                        <app-page :total="total" :current="pageNo" :pageSize="pageSize"
                            @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import apiurl from "@/api/public"
    import _api from "@/api/index"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {
        LOAD_CHILDREN_OPTIONS
    } from '@riophae/vue-treeselect'
    export default {
        components: {
            Treeselect
        },
        data() {
            var rolesValid = (rule, value, callback) => {
                callback()
            }
            return {
                form: {
                    orderId: null,
                    money: null,
                },
                loading: false,
                showadd: false,
                list: [],
                searchform:{},
                total: 1,
                pageNo: 1,
                pageSize: 10,
            }
        },
        created() {
            this.user=JSON.parse(localStorage.getItem("userinfo"))
            this.getList()
        },
        methods: {
            cancelar(row) {
                _api.get(apiurl.cancelarOrder, {
                    orderId: row.id,
                }).then(res => {
                    this.$notify({
                        title: this.$t('success'),
                        message: this.$t('success'),
                        type: 'success'
                    });
                    this.getList()

                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    });
                })
            },
            openedit(row) {
                this.showadd = true
                this.form.orderId = row.id
                this.form.money = row.cantidad
            },
            getList() {
                this.loading = true
                _api.get(apiurl.order, {
                    page: this.pageNo,
                    size: this.pageSize,
                    orderNo: this.searchform.claveRastreo,
                    createTime: this.value1,
                    iden:1
                }).then(res => {
                    this.loading = false
                    this.list = res.data.list
                    this.total = res.data.total
                })
            },
            onSubmit() {
                this.pageNo = 1
                this.getList()
            },
            handchange(data) { //分页Size变化
                this.pageSize = data
                this.pageNo = 1
                this.getList()
            },
            currentchange(data) { //当前页变化
                this.pageNo = data
                this.getList()
            },
        }
    }
</script>
<style scoped>
    .lefttop {
        padding: 8px;
    }

    .listbox {
        display: flex;
    }

    .listleft {
        width: 20%;
        border: 1px solid #EBEEF5;
    }

    .listright {
        width: 100%;
        margin-left: 1%;
    }

    .listrighttop {
        padding: 0 10px;
        background: rgb(245, 245, 245);
        height: 40px;
        overflow: hidden;
        line-height: 40px;
        border-bottom: 1px solid #EBEEF5;
    }

    .listrighttop strong {
        float: left;
    }

    .listrighttop span {
        float: right;
    }

    ::v-deep .vue-treeselect__control,
    ::v-deep .vue-treeselect__placeholder,
    ::v-deep .vue-treeselect__single-value {
        height: 30px;
        line-height: 30px;
    }
</style>